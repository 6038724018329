<template>
  <div>
    <!-- 给学生设置自定义的考卷 -->
    <el-dialog title="編輯學生的考卷" :visible.sync="setTests" width="60%">
      <div style="padding-top: 0">
        <div v-for="(exam, index) in reportcards" :key="index">
          <h5>Test {{ exam.which_week }}</h5>
          <div class="row">
            <div class="col-sm-6" style="display:flex; margin-bottom:20px">
              <div style="margin-right:10px;line-height:40px;width: 4rem;">
                <b>選擇類型</b>
              </div>
              <el-select
                v-model="exam.type"
                placeholder="選擇考卷類型"
                style="flex:1"
              >
                <el-option value="satmocks" label="SAT線上考試"></el-option>
                <el-option value="ivyway" label="Ivyway"></el-option>
              </el-select>
            </div>
            <div
              class="col-sm-6"
              style="display:flex; margin-bottom:20px"
              v-if="exam !== undefined"
            >
              <template v-if="exam.type === 'ivyway'">
                <div style="margin-right:10px;line-height:40px;width: 4rem;">
                  <b>選擇考試</b>
                </div>
                <el-select
                  filterable
                  style="flex: 1"
                  v-model="exam.new_exam_id"
                  @change="
                    $forceUpdate();
                    exam.new_user_exam_id = 0;
                  "
                >
                  <el-option label="暫不設置考卷。" :value="0"></el-option>
                  <el-option
                    v-for="(item, index) in tests || []"
                    :key="index"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6" style="display:flex; margin-bottom:20px">
              <div style="margin-right:10px;line-height:40px;width: 4rem;">
                <b>選擇考卷</b>
              </div>
              <el-select
                style="flex:1"
                v-show="exam.type === 'ivyway'"
                filterable
                v-model="exam.new_user_exam_id"
                @change="$forceUpdate()"
              >
                <el-option label="新考卷" :value="0"></el-option>
                <el-option
                  v-if="
                    !(exam.sat_score || exam.act_score) &&
                      exam.new_exam_id === exam.exam_id &&
                      exam.user_exam_id !== 0
                  "
                  label="權限已開啟,等待考試完成。"
                  :value="exam.user_exam_id"
                ></el-option>
                <template v-for="(item, index) in changeStudentExams">
                  <el-option
                    :key="index"
                    :label="
                      item.exam.full_name +
                        ' [' +
                        instant.formatDate(item.updated_at) +
                        ']'
                    "
                    :value="item.id"
                    v-if="exam.new_exam_id === item.exam_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-show="exam.type === 'satmocks'"
                filterable
                style="width:80%"
                v-model="exam.onlineSATIndex"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="(item, index) in changeSATStudentExams"
                  :key="index"
                  :label="
                    `${item.exam.title}: [${item.total_score}]
                        ${instant.formatDate(item.updated_at)}
                        `
                  "
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <hr />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setTests = false">{{
          $t("button.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveStudentExams">{{
          $t("button.save")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="class-link"
      title="Set Test"
      :visible.sync="showSelectExam"
      width="60%"
    >
      <el-alert title="Tips" type="info" show-icon :closable="false">
        <div>
          選擇學生歷史做過的該份練習，並設定為當前練習
        </div>
      </el-alert>
      <div v-if="studentExams">
        <el-table height="500" :data="studentExams">
          <el-table-column label="Test" min-width="100">
            <template slot-scope="scope">
              <el-tag
                class="mr-2"
                type="warning"
                v-if="
                  scope.row.other_status &&
                    scope.row.other_status.adaptive_practice_id
                "
              >
                <b>Adaptive</b>
              </el-tag>
              <a
                :href="
                  `${satIvywayUrl}/result/${
                    scope.row.id
                  }?practiceType=${getPracticeTypeLink(
                    scope.row.exam.category
                  )}&token=${token}`
                "
              >
                <b>
                  <span v-if="!isAdmin && scope.row.is_finished === 0">
                    {{ scope.row.exam.title }}
                  </span>
                  <span v-else>{{ scope.row.exam.title }}</span>
                </b>
              </a>
            </template>
          </el-table-column>
          <el-table-column label="Score" width="100">
            <template slot-scope="scope">
              <b class="text-success">
                {{ scope.row.total_score }}
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Status" width="120">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.is_finished === 0 ? 'info' : 'success'"
                disable-transitions
              >
                <span v-if="scope.row.is_finished === 0">
                  Incomplete
                </span>
                <b v-else>Completed</b>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Date" width="100">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm')
                "
                placement="top"
              >
                <span>{{
                  instant.formatGoogleTime(scope.row.updated_at)
                }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="100">
            <template slot-scope="scope">
              <template>
                <el-tooltip
                  class="ml-2"
                  effect="dark"
                  content="Select"
                  placement="top"
                  v-if="now_user_exam_id !== scope.row.id"
                >
                  <el-button
                    @click="reSetTest(scope.row.id)"
                    type="primary"
                    size="mini"
                  >
                    <i class="fas fa-exchange-alt"></i>
                  </el-button>
                </el-tooltip>
                <b class="text-success" v-else>
                  <i class="fa fa-check"></i>
                  Selected
                </b>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center mt-3 mb-5">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="10"
            :current-page="SATPage"
            @current-change="changePage"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-card v-if="classes && displaySession_class_assign_tests">
      <template
        v-if="displaySession_class_assign_tests.length > 0 && tableData"
      >
        <el-card
          v-for="(test, index) in displaySession_class_assign_tests"
          :key="test.id"
          class="mb-4"
          shadow="never"
        >
          <h5 class="mb-2">
            <div class="d-flex">
              <div>
                {{ getIvywayExamTitle(test.exam.title) }}
              </div>
              <template v-if="isAdmin || isTeacher">
                <el-tooltip effect="dark" content="" placement="top">
                  <div slot="content" style="width: 200px;">
                    Lock explanation and correct answer: Check this if you want
                    to hide explanations and correct answer from students when
                    they finish the test. If you hide the explanations and
                    correct answer, you can manually reveal it to individual
                    students later.
                  </div>
                  <el-checkbox
                    class="m-0 ml-2"
                    v-model="test.hide_exp_answer"
                    :true-label="1"
                    :false-label="0"
                    @change="hide_exp_answer => changeHide(test, hide_exp_answer)"
                    size="mini"
                    border
                  >
                    <i class="fas fa-lock"></i>
                  </el-checkbox>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="View Analysis"
                  placement="top"
                  v-show="test.exam.category !== 'third_full_test'"
                >
                  <a
                    class="ml-2"
                    :href="
                      `/session-classes/analysis/onlineSATDetail?class_id=${
                        section.id
                      }&week=${test.which_week}&session_class_assign_test_id=${
                        test.id
                      }&test_type=${testType}&exam_type=${getExamType(
                        test.exam ? test.exam.category : 0
                      )}
                        `
                    "
                    target="_blank"
                  >
                    <el-button type="success" size="mini" plain style="height: 27px;">
                      <i class="fas fa-chart-line"></i>
                    </el-button>
                  </a>
                </el-tooltip>
                <!-- <el-tooltip
                  effect="dark"
                  content="給所有學生開通新的考卷"
                  placement="top"
                  v-show="false"
                >
                  <el-button
                    class="ml-3"
                    type="primary"
                    size="mini"
                    @click="setExamAll(section.id, test.id)"
                  >
                    <i class="fas fa-users"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="給所有學生發送考試鏈接"
                  placement="top"
                  v-show="false"
                >
                  <el-button
                    class="ml-3"
                    type="primary"
                    size="mini"
                    @click="sendExamMailAll(section.id, index)"
                  >
                    <i class="fa fa-envelope"></i>
                  </el-button>
                </el-tooltip> -->
              </template>
            </div>
          </h5>
          <div style="max-width: 1080px;">
            <el-table
              :data="tableData[`Test${test.which_week}`]"
              style="width: 100%;"
              :key="test.id"
              max-height="250px"
            >
              <el-table-column
                prop="Students"
                label="Students"
                width="180"
                :key="`students_${test.id}`"
              >
                <template slot-scope="scope">
                  <b>
                    <a :href="'/user/' + scope.row.user.student_user_id">
                      {{
                        user.displayName(
                          scope.row.user.first_name,
                          scope.row.user.last_name
                        )
                      }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <el-table-column
                prop="Score"
                label="Score"
                :key="`score_${test.id}`"
              >
                <template slot-scope="scope">
                  <!-- 线上TOEFL分数 -->
                  <template
                    v-if="test.exam.test_type == 'App\\Entities\\Toefl'"
                  >
                    <!-- 老师管理员 -->
                    <template v-if="isTeacher || isAdmin">
                      <template v-if="scope.row.exam">
                        <div v-if="scope.row.exam.user_exam.is_finished">
                          <OnlineTOEFLScore
                            :tscore="scope.row.exam.user_exam.total_score"
                            :rscore_up="0"
                            :gscore_up="0"
                            :escore_up="0"
                            :mscore_up="0"
                            :score_up="0"
                            :canEdit="false"
                            :userExamId="scope.row.exam.user_exam.id"
                            :TOEFLToken="TOEFLToken"
                          ></OnlineTOEFLScore>
                        </div>
                        <div v-else class="tips">
                          <el-tooltip
                            effect="dark"
                            content="In Progress"
                            placement="top"
                          >
                            <a
                              v-if="scope.row.exam.user_exam.id"
                              :href="
                                `${TOEFLIvywayUrl}/result?id=${scope.row.exam.user_exam.id}&IvywayToken=${TOEFLToken}`
                              "
                            >
                              <el-tag
                                style="width: 12rem;"
                                type="warning"
                                effect="plain"
                                class="text-center"
                              >
                                <b>In Progress</b>
                              </el-tag>
                            </a>
                          </el-tooltip>
                        </div>
                      </template>
                      <div v-else>
                        <el-tag
                          style="width: 12rem;"
                          class="text-center"
                          type="info"
                          effect="plain"
                        >
                          <b>
                            <i class="fas fa-user"></i>
                            Pending
                          </b>
                        </el-tag>
                      </div>
                    </template>
                    <!-- 学生和家长 -->
                    <template v-else>
                      <div>
                        <template v-if="scope.row.exam">
                          <div v-if="scope.row.exam.user_exam.is_finished">
                            <OnlineTOEFLScore
                              :tscore="scope.row.exam.user_exam.total_score"
                              :rscore_up="0"
                              :gscore_up="0"
                              :escore_up="0"
                              :mscore_up="0"
                              :score_up="0"
                              :canEdit="false"
                              :userExamId="scope.row.exam.user_exam.id"
                              :TOEFLToken="TOEFLToken"
                            ></OnlineTOEFLScore>
                          </div>
                          <div v-else>
                            <el-tooltip
                              effect="dark"
                              content="In Progress"
                              placement="top"
                            >
                              <el-tag
                                style="width: 12rem;"
                                type="warning"
                                effect="plain"
                                class="text-center"
                              >
                                <b>In Progress</b>
                              </el-tag>
                            </el-tooltip>
                          </div>
                        </template>
                        <div v-else>
                          <el-tag
                            style="width: 12rem;"
                            class="text-center"
                            type="info"
                            effect="plain"
                          >
                            <b>
                              <i class="fas fa-user"></i>
                              Pending
                            </b>
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="isTeacher || isAdmin">
                      <div>
                        <template v-if="scope.row.exam">
                          <div v-if="scope.row.exam.user_exam.is_finished">
                            <OnlineSatScore
                              :escore="
                                scope.row.exam.user_exam.result
                                  ? scope.row.exam.user_exam.result[
                                      'english_score'
                                    ]
                                  : 0
                              "
                              :mscore="
                                scope.row.exam.user_exam.result
                                  ? scope.row.exam.user_exam.result[
                                      'math_score'
                                    ]
                                  : 0
                              "
                              :tscore="scope.row.exam.user_exam.total_score"
                              :question_count="scope.row.exam.question_count"
                              :testType="scope.row.exam.exam.category"
                              :rscore_up="0"
                              :gscore_up="0"
                              :escore_up="0"
                              :mscore_up="0"
                              :score_up="0"
                              :canEdit="false"
                              :userExamId="scope.row.exam.user_exam.id"
                              :title="scope.row.exam.exam.title"
                            ></OnlineSatScore>
                          </div>
                          <div v-else class="tips">
                            <el-tooltip
                              effect="dark"
                              content="In Progress"
                              placement="top"
                            >
                              <a
                                v-if="scope.row.exam.user_exam.id"
                                :href="
                                  `${satIvywayUrl}/result/${
                                    scope.row.exam.user_exam.id
                                  }?practiceType=${getPracticeTypeLink(
                                    scope.row.exam.exam.category
                                  )}&token=${token}`
                                "
                              >
                                <el-tag
                                  style="width: 12rem;"
                                  type="warning"
                                  effect="plain"
                                  class="text-center"
                                >
                                  <b
                                    v-if="
                                      scope.row.exam.user_exam.other_status
                                        .pastOrder
                                    "
                                  >
                                    <i class="fas fa-user-check"></i>
                                    {{
                                      `Module: ${scope.row.exam.user_exam.other_status.pastOrder.sectionOrder}`
                                    }},
                                    {{
                                      `Question: ${scope.row.exam.user_exam.other_status.pastOrder.questionOrder}`
                                    }}
                                  </b>
                                  <b v-else>
                                    <i class="fas fa-user-check"></i>
                                    Module: 1, Question: 1
                                  </b>
                                </el-tag>
                              </a>
                            </el-tooltip>
                          </div>
                        </template>
                        <div v-else>
                          <el-tag
                            style="width: 12rem;"
                            class="text-center"
                            type="info"
                            effect="plain"
                          >
                            <b>
                              <i class="fas fa-user"></i>
                              Pending
                            </b>
                          </el-tag>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        <template v-if="scope.row.exam">
                          <div v-if="scope.row.exam.user_exam.is_finished">
                            <OnlineSatScore
                              :escore="
                                scope.row.exam.user_exam.result
                                  ? scope.row.exam.user_exam.result[
                                      'english_score'
                                    ]
                                  : 0
                              "
                              :mscore="
                                scope.row.exam.user_exam.result
                                  ? scope.row.exam.user_exam.result[
                                      'math_score'
                                    ]
                                  : 0
                              "
                              :tscore="scope.row.exam.user_exam.total_score"
                              :question_count="scope.row.exam.question_count"
                              :testType="scope.row.exam.exam.category"
                              :rscore_up="0"
                              :gscore_up="0"
                              :escore_up="0"
                              :mscore_up="0"
                              :score_up="0"
                              :canEdit="false"
                              :userExamId="scope.row.exam.user_exam.id"
                              :title="scope.row.exam.exam.title"
                            ></OnlineSatScore>
                          </div>
                          <div v-else>
                            <el-tooltip
                              effect="dark"
                              content="In Progress"
                              placement="top"
                            >
                              <el-tag
                                style="width: 12rem;"
                                type="warning"
                                effect="plain"
                                class="text-center"
                              >
                                <b
                                  v-if="
                                    scope.row.exam.user_exam.other_status
                                      .pastOrder
                                  "
                                >
                                  <i class="fas fa-user-check"></i>
                                  {{
                                    `Module: ${scope.row.exam.user_exam.other_status.pastOrder.sectionOrder}`
                                  }},
                                  {{
                                    `Question: ${scope.row.exam.user_exam.other_status.pastOrder.questionOrder}`
                                  }}
                                </b>
                                <b v-else>
                                  <i class="fas fa-user-check"></i>
                                  Module: 1, Question: 1
                                </b>
                              </el-tag>
                            </el-tooltip>
                          </div>
                        </template>
                        <div v-else>
                          <el-tag
                            style="width: 12rem;"
                            class="text-center"
                            type="info"
                            effect="plain"
                          >
                            <b>
                              <i class="fas fa-user"></i>
                              Pending
                            </b>
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="Actions"
                label="Actions"
                width="250"
                :key="`actions_${test.id}`"
              >
                <template slot-scope="scope">
                  <div v-if="isAdmin || isTeacher">
                    <template
                      v-if="test.exam.test_type == 'App\\Entities\\Toefl'"
                    >
                    </template>
                    <template v-else>
                      <el-tooltip
                        effect="dark"
                        content="搜尋學生歷史做過的該份練習，並設定為當前練習"
                        placement="top"
                      >
                        <el-button
                          type="primary"
                          size="small"
                          @click="
                            selectExam(
                              scope.row.exam
                                ? scope.row.exam.user_exam.id
                                : null,
                              scope.row.user,
                              test.id,
                              test.exam.id,
                              scope.row.user.student_user_id
                            )
                          "
                          style="width: 8rem;"
                        >
                          <i class="fas fa-users-cog"></i>
                          Set Test
                        </el-button>
                      </el-tooltip>
                    </template>
                  </div>
                  <div v-else>
                    <!-- TOEFL -->
                    <template
                      v-if="test.exam.test_type == 'App\\Entities\\Toefl'"
                    >
                      <template v-if="scope.row.exam">
                        <div v-if="scope.row.exam.user_exam.is_finished == 0">
                          <a
                            :href="
                              `${TOEFLIvywayUrl}/test?id=${test.exam_id}&mode=0&user_exam_id=${scope.row.exam.user_exam.id}&IvywayToken=${TOEFLToken}`
                            "
                          >
                            <el-button
                              type="warning"
                              size="small"
                              style="width: 8rem;"
                            >
                              <i class="fas fa-play" />
                              Resume
                            </el-button>
                          </a>
                        </div>
                        <div v-else>
                          <a
                            :href="
                              `${TOEFLIvywayUrl}/result?id=${scope.row.exam.user_exam.id}&IvywayToken=${TOEFLToken}`
                            "
                          >
                            <b>
                              <i class="fas fa-eye" />
                              Testresults
                            </b>
                          </a>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <a
                            :href="
                              `${TOEFLIvywayUrl}/setTest/ivyway?exam_id=${test.exam_id}&classId=${$route.params.classId}&version=2&IvywayToken=${TOEFLToken}`
                            "
                          >
                            <el-button
                              type="success"
                              size="small"
                              style="width: 8rem;"
                            >
                              <i class="fas fa-play" />
                              Start a test
                            </el-button>
                          </a>
                        </div>
                      </template>
                    </template>
                    <!-- SAT -->
                    <template v-else>
                      <template v-if="scope.row.exam">
                        <div v-if="scope.row.exam.user_exam.is_finished == 0">
                          <a
                            :href="
                              `${satIvywayUrl}/test/${getPracticeTypeLink(
                                scope.row.exam.exam.category
                              )}/${scope.row.exam.exam.test_id}?user_exam_id=${
                                scope.row.exam.user_exam.id
                              }&token=${token}`
                            "
                          >
                            <el-button
                              type="warning"
                              size="small"
                              style="width: 8rem;"
                            >
                              <i class="fas fa-play" />
                              Resume
                            </el-button>
                          </a>
                        </div>
                        <div v-else>
                          <a
                            :href="
                              `${satIvywayUrl}/result/${
                                scope.row.exam.user_exam.id
                              }?practiceType=${getPracticeTypeLink(
                                scope.row.exam.exam.category
                              )}&token=${token}`
                            "
                          >
                            <b>
                              <i class="fas fa-eye" />
                              Testresults
                            </b>
                          </a>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <a
                            :href="
                              `${satIvywayUrl}/ivyway/test/${getPracticeTypeLink(
                                test.exam.category
                              )}/${
                                test.exam.test_id
                              }/start?session_class_assign_test_id=${
                                test.id
                              }&token=${token}`
                            "
                          >
                            <el-button
                              type="success"
                              size="small"
                              style="width: 8rem;"
                            >
                              <i class="fas fa-play" />
                              Start a test
                            </el-button>
                          </a>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Date" label="Date" width="150">
                <template slot-scope="scope">
                  <template v-if="scope.row.exam">
                    <span>
                      <!-- {{
                        instant.formatGoogleTime(
                          scope.row.exam.user_exam.updated_at
                        )
                      }} -->
                      {{ moment(scope.row.exam.user_exam.created_at).format('YYYY-MM-DD') }}
                    </span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>
              </el-table-column>
            </el-table>
            <div
              class="d-flex mt-2"
              style="align-items:center"
              v-if="isTeacher || isAdmin"
            >
              <h6 class="pl-2 mr-2 mb-0" style="width: 180px;">
                AVG
                <el-tooltip
                  class="item"
                  content="平均分只統計完成的練習"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span class="action-icon">
                    <i class="fas fa-question-circle" />
                  </span>
                </el-tooltip>
              </h6>
              <OnlineSatScore
                v-if="scoreData[`Test${test.which_week}`].length > 0"
                :escore="
                  showScore(
                    scoreData[`Test${test.which_week}`].english_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :mscore="
                  showScore(
                    scoreData[`Test${test.which_week}`].math_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :tscore="
                  showScore(
                    scoreData[`Test${test.which_week}`].total_score,
                    scoreData[`Test${test.which_week}`].length
                  )
                "
                :question_count="0"
                :testType="0"
                :rscore_up="0"
                :gscore_up="0"
                :escore_up="0"
                :mscore_up="0"
                :score_up="0"
                :canEdit="false"
              ></OnlineSatScore>
            </div>
          </div>
        </el-card>
      </template>
      <el-empty v-else></el-empty>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SatScore from "@/views/sessionClasses/components/SatScore.vue";
import OnlineSatScore from "@/views/sessionClasses/components/OnlineSatScore.vue";
import OnlineTOEFLScore from "@/views/sessionClasses/components/OnlineTOEFLScore.vue";
import ActScore from "@/views/sessionClasses/components/ActScore.vue";
import courseApi from "@/apis/course";
import Tests from "@/apis/tests.js";
import { instant, user } from "@ivy-way/material";
import role from "@/mixins/role";
import _ from "lodash";
import moment from "moment";

export default {
  props: [
    "testState",
    "section",
    "disableAvg",
    "disableEdit",
    "isMergeCheck",
    "mergeCheckDependent",
    "displayStudentUserIds",
    "type"
  ],
  mixins: [role],
  components: {
    // SatScore,
    OnlineSatScore,
    OnlineTOEFLScore
    // ActScore,
    // ToeflScore
  },
  data() {
    return {
      moment,
      classes: null,
      setResult: false,
      setTests: false,
      reportcards: [],
      studentExam: {
        student_class_ticket_id: 0,
        test: "",
        student: "",
        whichTest: 0,
        exams: [],
        user_exam: "",
        exam_id: 0,
        sessionClass: 0
      },
      activeStudent_class_ticket_id: null,
      tests: [],
      changeStudentExams: [],
      changeSATStudentExams: [],
      form: null,
      showSelectExam: false,
      studentExams: null,
      SATPage: 1,
      pageTotal: 0,
      nowStudentClassTicket: null,
      nowSessionClassExamId: null,
      now_user_id: null,
      now_user_exam_id: null,
      tableData: null,
      scoreData: null,
      TOEFLToken: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testType() {
      return this.$route.query.test_type
        ? this.$route.query.test_type
        : "weekly";
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    satIvywayUrl() {
      return process.env.VUE_APP_SAT_IVYWAY_FRONTEND_DOMAIN;
    },
    TOEFLIvywayUrl() {
      return process.env.VUE_APP_TOEFL_IVYWAY_FRONTEND_DOMAIN;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    needToFilterStudent() {
      return this.displayStudentUserIds;
    },
    displaySession_class_assign_tests() {
      let arr = this.classes.session_class_assign_tests.filter(
        test => this.getType(test.exam ? test.exam.category : 0) === this.type
      );
      arr = _.reverse(_.sortBy(arr, "which_week"));
      arr.forEach(session_class_assign_test => {
        if (
          session_class_assign_test.hide_answer &&
          session_class_assign_test.hide_exp
        ) {
          this.$set(session_class_assign_test, "hide_exp_answer", 1);
        } else {
          this.$set(session_class_assign_test, "hide_exp_answer", 0);
        }
      });
      return arr;
    },
    displayStudents() {
      if (this.displayStudentUserIds) {
        return this.classes.student_class_tickets.filter(
          ({ student_user_id }) =>
            this.displayStudentUserIds.includes(student_user_id)
        );
      } else {
        return this.classes.student_class_tickets;
      }
    },
    getSuperScoreTotal() {
      let superScoreTotal = {
        max_escore: 0,
        max_escore_up: 0,
        max_gscore: 0,
        max_gscore_up: 0,
        max_mscore: 0,
        max_mscore_up: 0,
        max_rscore: 0,
        max_rscore_up: 0,
        max_score: 0,
        max_score_up: 0
      };
      (this.classes.student_class_tickets || []).forEach(student => {
        for (const score in superScoreTotal) {
          superScoreTotal[score] += student[score];
        }
      });
      return superScoreTotal;
    },
    getSuperScoreCount() {
      let count = 0;
      (this.classes.student_class_tickets || []).forEach(student => {
        if (student.max_escore !== 0) {
          count += 1;
        }
      });
      return count;
    }
  },
  watch: {
    section() {
      this.initialize();
    }
  },
  created() {
    this.getTOEFLToken();
    this.initialize();
  },
  methods: {
    async changeHide(test, value) {
      test.hide_exp_answer = value;
      // test.hide_exp = value;
      // test.hide_answer = value;
      const res = await courseApi.pathHide(test.id, {
        hide_exp: value,
        hide_answer: value
      });
    },
    async getTOEFLToken() {
      const res = await courseApi.getTestAdmitToken();
      console.log(res);
      this.TOEFLToken = res.third_login_user.client_token;
      console.log(this.TOEFLToken);
    },
    getTestAvg() {},
    async reSetTest(user_exam_id) {
      const res = await Tests.reSetTest(this.nowSessionClassExamId, {
        student_class_ticket_id: this.nowStudentClassTicket.id,
        user_exam_id: user_exam_id,
        user_exam_type: "App\\Entities\\SatMocks\\UserExam"
      });
      this.showSelectExam = false;
      this.$emit("getSessions");
    },
    setData() {
      let tableData = {};
      let scoreData = {};
      this.classes.session_class_assign_tests.forEach(test => {
        let score = {
          english_score: 0,
          math_score: 0,
          total_score: 0,
          length: 0
        };
        this.displayStudents.forEach(student => {
          let user = student;
          let exam = null;
          if (student.student_class_ticket_exams) {
            Object.keys(student.student_class_ticket_exams).forEach(function(
              key
            ) {
              if (key === `Test${test.which_week}`) {
                exam = student.student_class_ticket_exams[key];
                if (
                  exam.user_exam &&
                  exam.user_exam.result &&
                  exam.user_exam &&
                  exam.user_exam.is_finished
                ) {
                  score.english_score +=
                    exam.user_exam &&
                    exam.user_exam.result &&
                    exam.user_exam.result.english_score
                      ? exam.user_exam.result.english_score
                      : 0;
                  score.math_score +=
                    exam.user_exam &&
                    exam.user_exam.result &&
                    exam.user_exam.result.math_score
                      ? exam.user_exam.result.math_score
                      : 0;
                  score.total_score += exam.user_exam
                    ? exam.user_exam.total_score
                    : 0;
                  score.length++;
                }
              }
            });
          }
          if (tableData[`Test${test.which_week}`]) {
            tableData[`Test${test.which_week}`].push({
              user: user,
              exam: exam
            });
          } else {
            tableData[`Test${test.which_week}`] = [];
            tableData[`Test${test.which_week}`].push({
              user: user,
              exam: exam
            });
          }
        });
        scoreData[`Test${test.which_week}`] = score;
      });
      this.tableData = tableData;
      this.scoreData = scoreData;
      // console.log(scoreData);
      // console.log(tableData);
    },
    selectExam(
      user_exam_id,
      studentClassTicket,
      sessionClassExam,
      exam_id,
      user_id
    ) {
      console.log(
        user_exam_id,
        studentClassTicket,
        sessionClassExam,
        exam_id,
        user_id
      );
      this.studentExams = null;
      this.now_user_exam_id = user_exam_id;
      this.nowStudentClassTicket = studentClassTicket;
      this.nowSessionClassExamId = sessionClassExam;
      this.nowExamId = exam_id;
      this.now_user_id = user_id;
      this.getStudentsExams();
    },
    async getStudentsExams() {
      this.SATPage = 1;
      const res = await Tests.getStudentsExamsFromSAT(this.now_user_id, {
        per_page: 10,
        page: this.SATPage,
        exam_id: this.nowExamId,
        is_finished: null
      });
      this.studentExams = res.user_exams.data;
      this.pageTotal = res.user_exams.total;
      this.showSelectExam = true;
    },
    async changePage(page) {
      this.SATPage = page;
      const res = await Tests.getStudentsExamsFromSAT(this.now_user_id, {
        per_page: 10,
        page: this.SATPage,
        exam_id: this.nowExamId,
        is_finished: null
      });
      this.studentExams = res.user_exams.data;
      this.pageTotal = res.user_exams.total;
    },
    getPracticeTypeLink(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "singlePractice";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    getType(category) {
      if (category === "third_full_test") {
        return "tests";
      } else {
        if (category > 14) {
          return "tests";
        } else {
          return "homework";
        }
      }
    },
    getExamType(category) {
      if (category > 14) {
        return "composePractices";
      } else {
        return "singlePractice";
      }
    },
    async setExamAll(id, session_class_assign_test_id) {
      let student_user_ids = [];
      this.displayStudents.forEach(student => {
        student_user_ids.push(student.student.id);
      });
      this.$confirm("將要給所有學生開通新的考卷, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendAndSetExam(id, {
            student_user_ids: student_user_ids,
            session_class_assign_test_id
          });
          this.$message({
            message: "已開通！",
            type: "success"
          });
          this.$emit("getSessions");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async setExamUser(user_id, id, session_class_assign_test_id) {
      let student_user_ids = [user_id];
      this.$confirm("開通新的考卷, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendAndSetExam(id, {
            student_user_ids: student_user_ids,
            session_class_assign_test_id
          });
          this.$message({
            message: "已開通！",
            type: "success"
          });
          this.$emit("getSessions");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async sendExamMailAll(id, week) {
      let student_user_ids = [];
      this.displayStudents.forEach(student => {
        student_user_ids.push(student.student.id);
      });
      this.$confirm("給所有已經開通考卷的學生發送考試鏈接, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendExamMail(id, {
            student_user_ids: student_user_ids,
            which_week: week
          });
          this.$message({
            message: "發送成功！",
            type: "success"
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async sendExamMail(user_id, id, week) {
      let student_user_ids = [user_id];
      this.$confirm("發送考試鏈接, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendExamMail(id, {
            student_user_ids: student_user_ids,
            which_week: week
          });
          this.$message({
            message: "發送成功！",
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    getScore(userExamId) {
      this.getUserExamInfo(userExamId);
    },
    async getUserExamInfo(userExamId) {
      const result = await Tests.getUserExamInfo(userExamId);
      this.form = result;
      this.startGrading();
    },
    async startGrading() {
      if (this.form.category == "Sat" || this.form.category == "Act") {
        this.routerPush({
          name: "GradingExam",
          query: {
            user_exam_id: this.form.user_exam_id
          }
        });
      } else if (this.form.category == "Compose Practice") {
        this.getTeacherClassExams();
      } else if (this.form.category == "Toefl") {
        this.getTeacherClassExams();
      }
    },
    async getTeacherClassExams() {
      const res = await Tests.getTeacherClassExams(this.form.exam_id);
      if (res.toefl_exam) {
        this.routerPush({
          name: "SetTest",
          query: {
            title: res.toefl_exam.title,
            testType: "mock",
            version: res.toefl_exam.type
          }
        });
      }
      if (res.compose_practice_id) {
        this.$router.push({
          name: "FullPractice",
          params: {
            fullPracticeId: res.compose_practice_id
          }
        });
      }
    },
    initialize() {
      this.getAssignTest();
      this.classes = this.setClasses(JSON.parse(JSON.stringify(this.section)));
      this.setData();
    },
    async getAssignTest() {
      if (this.testState) {
        this.tests = this.testState.map(test => test);
      } else {
        const tests = await courseApi.getExams();
        this.tests = tests.exams;
      }
    },
    setClasses(sectionClass) {
      sectionClass["checkedAll"] = true;
      (sectionClass.student_class_tickets || []).forEach(student => {
        student["is_checked"] = true;
        let old_rscore = 0;
        let old_gscore = 0;
        let old_escore = 0;
        let old_mscore = 0;
        let old_score = 0;

        // 计算super score
        let first_rscore = 0;
        let first_gscore = 0;
        let first_escore = 0;
        let first_mscore = 0;
        let first_score = 0;

        let max_rscore = 0;
        let max_gscore = 0;
        let max_escore = 0;
        let max_mscore = 0;
        let max_score = 0;

        let count = 0;
        sectionClass.session_class_assign_tests.forEach((exam, index) => {
          let full_name = { full_name: "無" };
          sectionClass.exams.forEach(val => {
            if (val.id === exam.exam_id) {
              full_name = val;
            }
          });
          exam["full_name"] = full_name.full_name;
          exam["count"] = 0;
          exam["r_total"] = 0;
          exam["g_total"] = 0;
          exam["e_total"] = 0;
          exam["m_total"] = 0;
          exam["t_total"] = 0;

          exam["r_up"] = 0;
          exam["g_up"] = 0;
          exam["e_up"] = 0;
          exam["m_up"] = 0;
          exam["t_up"] = 0;

          if (
            student.student_class_ticket_exams !== undefined &&
            student.student_class_ticket_exams["Test" + index] !== undefined
          ) {
            if (
              student.student_class_ticket_exams["Test" + index].sat_score !==
                null &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\UserExam"
            ) {
              count++;
              // student.student_class_ticket_exams["Test" + index].sat_score
              if (count === 1) {
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "rscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "gscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "escore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "mscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "score_up"
                ] = 0;
                //获取初次考试
                first_rscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score;
                first_gscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score;
                first_escore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_english_score;
                first_mscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score;
                first_score =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .total_score;
              } else {
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "rscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score - old_rscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "gscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score - old_gscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "escore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_english_score - old_escore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "mscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score - old_mscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "score_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .total_score - old_score;
              }
              //Save Old Score
              old_rscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .reading_score;
              old_gscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .writing_score;
              old_escore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_english_score;
              old_mscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_math_score;
              old_score =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .total_score;
              // Super score
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .reading_score > max_rscore
              ) {
                max_rscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score;
              }
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .writing_score > max_gscore
              ) {
                max_gscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score;
              }
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_math_score > max_mscore
              ) {
                max_mscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score;
              }
            }
          }
        });
        max_escore = (max_rscore + max_gscore) * 10;
        max_score = max_escore + max_mscore;
        let max_rscore_up = max_rscore - first_rscore;
        let max_gscore_up = max_gscore - first_gscore;
        let max_escore_up = max_escore - first_escore;
        let max_mscore_up = max_mscore - first_mscore;
        let max_score_up = max_score - first_score;
        student["max_rscore"] = max_rscore;
        student["max_gscore"] = max_gscore;
        student["max_escore"] = max_escore;
        student["max_mscore"] = max_mscore;
        student["max_score"] = max_score;

        student["max_rscore_up"] = max_rscore_up;
        student["max_gscore_up"] = max_gscore_up;
        student["max_escore_up"] = max_escore_up;
        student["max_mscore_up"] = max_mscore_up;
        student["max_score_up"] = max_score_up;
      });
      this.getAvg(sectionClass);
      return sectionClass;
    },
    checked(section, student) {
      student.is_checked = !student.is_checked;
      let count = 0;
      section.student_class_tickets.forEach(test => {
        if (test["is_checked"] == true) {
          count++;
        }
      });
      if (section.student_class_tickets.length === count) {
        section.checkedAll = true;
      } else {
        section.checkedAll = false;
      }
      this.getAvg(section);
    },
    checkedAll(section) {
      section.checkedAll = !section.checkedAll;
      section.student_class_tickets.forEach(student => {
        student.is_checked = section.checkedAll;
      });
      this.getAvg(section);
    },
    selectStudent(val) {
      console.log(val);
    },
    getScoreAvg(param) {
      const { columns, data } = param;
    },
    getSummaries(param) {
      const { columns, data } = param; //columns是每列的信息，data是每行的信息
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "AVG"; //此处是在index=0的这一列显示为“合计”
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // console.log(values);    [2327682, 2322647]
        // console.log(column.property); //hflowAccStart  就是这一列的那个参数名，也是根据这个进行判断 prop绑定的字段

        //截至到这，上面和官网一样的
        //下面开始判断，你想要显示的每一列，去取他们的值返回
        if (
          column.property === "buildArea" ||
          column.property === "secondInstantCool" ||
          column.property === "Score"
        ) {
          if (column.property === "Score") {
            let sum = 0;
            console.log(column);
            values.forEach(item => {
              sum = sum + item;
            });
            sums[index] = sum / values.length;
          } else {
            //官网是不为空的条件，我改成我想要的列，
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          }
        }
      });
      return sums; //然后....sums就是我想要的结果了
    },
    getAvg(section) {
      let r_old = 0;
      let g_old = 0;
      let e_old = 0;
      let m_old = 0;
      let t_old = 0;
      (section.session_class_assign_tests || []).forEach((exam, index) => {
        exam.r_total = 0;
        exam.g_total = 0;
        exam.e_total = 0;
        exam.m_total = 0;
        exam.t_total = 0;
        exam.r_up = 0;
        exam.g_up = 0;
        exam.e_up = 0;
        exam.m_up = 0;
        exam.t_up = 0;
        exam["count"] = 0;
        exam["onlineCount"] = 0;
        (section.student_class_tickets || []).forEach(student => {
          if (student.is_checked) {
            if (
              student.student_class_ticket_exams !== undefined &&
              student.student_class_ticket_exams["Test" + index] !==
                undefined &&
              student.student_class_ticket_exams["Test" + index].sat_score !==
                null &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\UserExam"
            ) {
              exam["count"]++;
              exam.r_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.reading_score;

              exam.g_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.writing_score;
              exam.e_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.section_english_score;
              exam.m_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.section_math_score;

              exam.t_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.total_score;
            }
            if (
              student.student_class_ticket_exams !== undefined &&
              student.student_class_ticket_exams["Test" + index] !==
                undefined &&
              student.student_class_ticket_exams["Test" + index].user_exam &&
              student.student_class_ticket_exams["Test" + index].user_exam
                .is_finished == 1 &&
              student.student_class_ticket_exams["Test" + index].user_exam
                .result &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\SatMocks\\UserExam"
            ) {
              exam["onlineCount"]++;
              exam.e_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.result.english_score;
              exam.m_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.result.math_score;

              exam.t_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.total_score;
            }
          }
        });

        if (t_old > 0) {
          if (exam["count"] > 0) {
            exam.r_up =
              this.showScore(exam.r_total * 10, exam["count"]) - r_old;
            exam.g_up =
              this.showScore(exam.g_total * 10, exam["count"]) - g_old;
            exam.e_up = exam.r_up + exam.g_up;
            exam.m_up = this.showScore(exam.m_total, exam["count"]) - m_old;
            exam.t_up = exam.r_up + exam.g_up + exam.m_up;
          }
        }
        r_old = this.showScore(exam.r_total * 10, exam["count"]);
        g_old = this.showScore(exam.g_total * 10, exam["count"]);
        e_old = r_old + g_old;
        m_old = this.showScore(exam.m_total, exam["count"]);
        t_old = e_old + m_old;
      });
    },
    showScore(total, count) {
      let score = "";
      if (count > 0) {
        score = Math.round(total / count);
      }
      return score;
    },
    async saveStudentExams() {
      let exams = [];
      for (let i in this.reportcards) {
        if (
          this.reportcards[i] !== undefined &&
          this.reportcards[i].exam_id > 0
        ) {
          if (this.reportcards[i].type === "satmocks") {
            exams.push({
              user_exam_type: "App\\Entities\\SatMocks\\UserExam",
              times: this.reportcards[i].which_week,
              exam_id: this.changeSATStudentExams[
                this.reportcards[i].onlineSATIndex
              ].exam_id,
              user_exam_id: this.changeSATStudentExams[
                this.reportcards[i].onlineSATIndex
              ].id
            });
          } else {
            if (
              this.reportcards[i].new_user_exam_id !==
              this.reportcards[i].user_exam_id
            ) {
              exams.push({
                user_exam_type: "App\\Entities\\UserExam",
                times: this.reportcards[i].which_week,
                exam_id: this.reportcards[i].new_exam_id,
                user_exam_id: this.reportcards[i].new_user_exam_id
              });
            }
          }
        }
      }
      if (exams.length > 0) {
        const res = await courseApi.saveStudentExams(
          this.activeStudent_class_ticket_id,
          {
            exams: exams
          }
        );
      }
      this.setTests = false;
      // this.$router.go(0);
    },
    async editReportcards(
      reportcards,
      student_class_ticket_id,
      student_user_id
    ) {
      this.reportcards = { ...reportcards };
      this.activeStudent_class_ticket_id = student_class_ticket_id;
      const res = await courseApi.getStudentExams(student_user_id);
      this.setTests = true;
      if (res.user_exams) {
        this.changeStudentExams = res.user_exams;
      } else {
        this.changeStudentExams = [];
      }
      if (res.sat_mocks_user_exams) {
        this.changeSATStudentExams = res.sat_mocks_user_exams;
      } else {
        this.changeSATStudentExams = [];
      }

      this.classes.session_class_assign_tests.forEach((item, index) => {
        if (this.reportcards["Test" + item.which_week] !== undefined) {
          // this.reportcards[i] = reportcards["Test" + i];
          this.reportcards["Test" + item.which_week]["can_correct"] = 0;
          this.reportcards["Test" + item.which_week][
            "new_user_exam_id"
          ] = this.reportcards["Test" + item.which_week]["user_exam_id"];
          this.reportcards["Test" + item.which_week][
            "new_exam_id"
          ] = this.reportcards["Test" + item.which_week]["exam_id"];
        } else {
          this.reportcards["Test" + item.which_week] = {
            act_score: null,
            can_correct: 0,
            created_at: "",
            exam: "",
            exam_id: item.exam_id,
            new_exam_id: item.exam_id,
            id: 0,
            sat_score: null,
            student_class_ticket_id: 0,
            updated_at: "",
            user_exam_id: 0,
            new_user_exam_id: 0,
            which_week: item.which_week,
            onlineSATIndex: null
          };
        }
        // this.reportcards["Test" + item.which_week]["type"] =  "ivyway";
      });
      this.reportcards = _.orderBy(this.reportcards, ["which_week", "asc"]);
      // for(let i in this.reportcards){
      //   if (this.reportcards[i] !== undefined) {
      //     // this.reportcards[i] = reportcards["Test" + i];
      //     this.reportcards[i]["can_correct"] = 0;
      //     this.reportcards[i]["new_user_exam_id"] =  this.reportcards[i]["user_exam_id"];
      //     this.reportcards[i]["new_exam_id"] =  this.reportcards[i]["exam_id"];
      //   }
      // }
    },
    async setExam(student, test, whichTest, course_session_id) {
      this.studentExam = {
        student_class_ticket_id: 0,
        test: "",
        student: "",
        whichTest: 0,
        exams: [],
        user_exam: "",
        exam_id: 0,
        sessionClass: 0
      };
      let exams = await courseApi.getStudentExams(student.student_user_id);
      this.studentExam.student_class_ticket_id = student.id;
      this.studentExam.exams = exams.user_exams;
      this.studentExam.student = student.first_name + " " + student.last_name;
      this.studentExam.test = test;
      this.studentExam.whichTest = whichTest;
      this.studentExam.sessionClass = course_session_id;
      this.setResult = true;
    },
    async saveStudentExam() {
      this.setResult = false;
      let userExam = this.studentExam.exams[this.studentExam.user_exam];
      await courseApi.setStudentExams(
        this.studentExam.student_class_ticket_id,
        {
          which_week: this.studentExam.whichTest,
          exam_id: userExam.exam_id,
          user_exam_id: userExam.id
        }
      );
      this.$emit("getSessions");
    },
    async removeStudentExam(student, which_week) {
      await courseApi.setStudentExams(
        student.student_class_ticket_exams["Test" + which_week]
          .student_class_ticket_id,
        {
          which_week: which_week,
          exam_id: 0,
          user_exam_id: 0
        }
      );
      this.$emit("getSessions");
    }
  }
};
</script>

<style scoped>
::v-deep .tips .el-alert {
  padding: 0.5rem;
}
::v-deep .tips .el-alert__icon {
  font-size: 1rem;
  width: 1.1rem;
}
.table td {
  vertical-align: middle;
}

td,
th {
  /* 设置td,th宽度高度 */
  border: 1px solid gray;
  width: 100px;
  height: 30px;
}

table {
  table-layout: fixed;
  min-width: 200px; /* 固定宽度 */
}
th,
td {
  background-color: white;
}
td:first-child,
th:first-child {
  position: sticky;
  left: 0; /* 首行永远固定在左侧 */
  z-index: 1;
  width: 120px !important;
  background-color: white;
  border-right: 1px solid gray;
  text-align: center;
}
td:nth-child(2),
th:nth-child(2) {
  position: sticky;
  left: 120px; /* 首行永远固定在左侧 */
  min-width: 120px !important;
  z-index: 1;
  background-color: white;
  border-right: 1px solid gray;
}
thead tr th {
  position: sticky;
  top: 0; /* 列首永远固定在头部  */
}
th:first-child {
  z-index: 2;
  /* background-color:lightblue; */
}
</style>
